@import "../../styles/common";

.chapter-reader {
    .book-title {
        font-size: 23px;
        font-weight: normal;
        text-align: left;
        font-variant: small-caps;
        padding: 0;
        margin: 0;
        font-family: $scripture-font;
    }
    .book-subtitle {
        font-size: 13px;
        font-weight: normal;
        text-align: left;
        font-variant: small-caps;
        padding: 0;
        margin: 0;
        font-family: $scripture-font;
    }
    & > .row {
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        padding-top: .2em;
        padding-bottom: .2em;
        margin-bottom: .5em;
    }
    .book-title-area {
        flex: 7 0 auto;
    }
    .chapter-title-area {
        flex: 5 0 auto;
    }
    &:not(:last-child) {
        list-style: none;
        margin-bottom: 4px;
        clear: both;
    }
    & label {
        width: 140px;
        float: left;
        text-align: right;
    }
    & label a {
        color: #000;
    }
}