@import "../../styles/common";

.book-reader {
    ul.chapters {
        margin-top: 0;
        margin-bottom: 15px;
        list-style: none;
        margin-left: 0;
        padding-left: 0;
        .chapter-title {
            font-size: 20px;
            font-family: $scripture-font;
            font-weight: normal;
            text-align: right;
            font-variant: small-caps;
            margin: 0;
        }
        li {
            display: inline;
            margin-right: 3px;
        }
    }
}