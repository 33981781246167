@import "../../styles/colors";
@import "../../styles/common";

.scriptures {
    .narrow {
        max-width: 65rem;
        margin-left: auto;
        margin-right: auto;
    }

    .today {
        margin-top: 4rem;
        clear: both;
        img {
            max-width: 10rem;
            float: left;
            margin-top: 0.7rem;
            margin-right: 1rem;
        }
        h4 {
            padding-left: 11rem;
            margin-bottom: 0;
        }
        p {
            padding-left: 11rem;
        }
    }

    .scriptures-next {
        //border-top: 1px solid #ddd;
        padding-bottom: 1rem;
        color: $neutral;
        button {
            width: 100%;
            @media (min-width: 825px) {
                // width: auto;
            }
        }
        p {
            margin-bottom: 0;
        }
    }

    .yesterday-reading, .tomorrow-reading {
        flex: auto;
    }
}
