@import "styles/custom";
@import "styles/common";
@import "styles/mixins";
@import "styles/utilities";

@import "styles/skeleton";

.container {
    min-width: $app-min-width;
}

.main {
    background-color: $neutral;
    padding-top: 1.5rem;;
    min-width: $app-min-width;
    min-height: 300px;
    h1, h2, h3, h4, h5, h6 {
        letter-spacing: 1px;
    }
}