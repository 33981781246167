@import "../../styles/common";

.verse-reader {
    &.verses {
        .verse {
            font-size: 2.3rem;
            font-family: $scripture-font;
            margin-bottom: -0.0rem;
            line-height: 3.2rem;
            letter-spacing: 0.005rem;
            padding: 5px;
            position: relative;
            .numLoves {
                $background: $neutral;
                font-family: "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
                position: relative;
                font-size: 1.3rem;
                display: inline-block;
                text-align: center;
                border: 1px solid $accent-muted;
                border-radius: 0.3rem;
                padding: 0 0.5rem;
                line-height: 2.5rem;
                top: -3px;
                letter-spacing: 0.7px;
                &:after, &:before {
                    right: 100%;
                    top: 50%;
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                }
        
                &:after {
                    border-color: rgba(255, 183, 120, 0);
                    border-right-color: $background;
                    border-width: 5px;
                    margin-top: -5px;
                }
                &:before {
                    border-color: rgba(255, 127, 42, 0);
                    border-right-color: $accent-muted;
                    border-width: 6px;
                    margin-top: -6px;
                }
            }
            .love {
                font-size: 1.9rem;
                padding-right: 0.8rem;
                button {
                    color: $accent-muted;
                    margin: 0;
                    padding: 0;
                    border: 0;
                    font-size: 1.9rem;
                }
            }
        }

        .versenum {
            margin-left: 0.7rem;
            margin-right: 0rem;
            font-family: $scripture-font;
            font-size: 2rem;
            color: #ccc;
        }
    }
}