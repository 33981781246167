.ScheduleDisplay {
    width: 100%;
    padding: 1rem;
    margin-top: 3rem;
    margin-bottom: 4rem;

    $image-width: 24%;
    .red-bookmark {
        float: left;
        margin-left: -0.3rem;
        max-width: $image-width;
    }
    & p,
    & h2,
    & h3 {
        padding-left: $image-width + 2.8%;
    }
    h2 {
        margin-top: 0;
        padding-top: 0.6rem;
        margin-bottom: 0.5rem;
        font-size: 3.2rem; // fallback
        font-size: 11vw;
    }
    h3 {
        font-size: 1.8rem; //fallback
        font-size: 6.4vw;
    }
    p {
        font-size: 1.2rem;
        font-size: 5vw;
        margin-bottom: 0.2rem;
    }
    .schedule-link {
        margin-top: 1rem;
        font-size: 1.2rem;
        font-size: 5vw;
    }
    button {
        width: 18rem;
        padding-left: 15px;
        padding-right: 15px;
        margin-left: -0.4rem;
        margin-top: 1.7rem;
    }

    @media (min-width: 389px) {
        .red-bookmark {
            max-width: 9rem;
        }
        & p,
        & h2,
        & h3 {
            padding-left: 9rem;
        }
        h2 {
            font-size: 4.3rem;
            padding-top: 0.7rem;
        }
        h3 {
            font-size: 2.3rem;
        }
        p,
        .schedule-link {
            font-size: 1.7rem;
        }
    }

    @media (min-width: 550px) {
        .red-bookmark {
            max-width: 6rem;
        }
        & p,
        & h2,
        & h3 {
            padding-left: 7rem;
        }
        h2 {
            font-size: 4rem;
            padding-top: 0.2rem;
        }
        h3 {
            font-size: 2.3rem;
        }
        p,
        .schedule-link {
            font-size: 2.1rem;
        }
    }

    @media (min-width: 750px) {
        .red-bookmark {
            max-width: 8rem;
        }
        & p,
        & h2,
        & h3 {
            padding-left: 11rem;
        }
        h2 {
            font-size: 5.4rem;
        }
        h3 {
            font-size: 3.1rem;
        }
        p,
        .schedule-link {
            font-size: 2.3rem;
        }
    }
}
