@import '../../styles/colors';
@import '../../styles/common';

.loading {
    padding: 1rem;
    text-align: center;

    h2 {
        font-size: 4.2rem;
        font-weight: lighter;
    }
}