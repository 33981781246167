@import '../../styles/colors';

footer {
    color: $neutral;
    padding: 3rem;
    text-align: center;
    min-height: 15rem;
    font-size: 2.5rem;

    .copyright {
        font-size: 1rem;
    }
}

.footer-social {
    font-size: 0.8rem;
    max-height: 15rem;
    text-align: left;

    div {
        display: inline-block;
    }

    .fb-iframe {
        margin-top: 0.5rem;
        border: none;
        overflow: hidden;
        width: 100%;
        height: 150px;;
    }
}
