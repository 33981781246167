@import "../../styles/mixins";
@import "../../styles/colors";
@import "../../styles/utilities";
@import "../../styles/common";

$background-pattern-retina: url('../../images/textures/wild_oliva_@2X.png');
$background-pattern: url('../../images/textures/wild_oliva.png');

header {
    z-index: 2;
    color: $neutral;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: $header-height;
    padding-bottom: 0;
    background-image: $background-pattern;
    @include retina {
        background-image: $background-pattern-retina;
    }

    .logo {
        height: 6.3rem;
        margin-top: 1.3rem;
        @media (min-width: 390px) and (max-width: 550px) {
            height: 5.7rem;
            margin-top: 1.1rem;
            margin-bottom: 0.5rem;
        }
        @media (min-width: 375px) and (max-width: 389px) {
            height: 3.7rem;
            margin-top: 3rem;
            margin-bottom: 0;
        }
        @media (max-width: 374px) {
            height: 2.7rem;
            margin-top: 3rem;
            margin-bottom: 0;
        }
    }

    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 0;
    }

    .flex {
        display: flex;
        align-items: center;
        height: $header-height - 3rem;

        @media (min-width: 750px) {
            height: $header-height;
        }
    }

    .help-button {
        justify-content: flex-end;
        @media screen and (min-width: 0px) and (max-width: 353px) {
            margin-top: -0.4rem;
        }

        .non-button {
            color: $neutral;
            text-decoration: none;
            cursor: pointer;
            border: none;
            text-transform: none;
        }
    }

    .fb-like {
        max-height: 5rem;

        @media (min-width: 550px) {
            margin-top: 2.4rem;
            justify-content: flex-end;
        }

        @media (min-width: 0px) and (max-width: 389px) {
            justify-content: flex-end;
            margin-top: -3rem;
        }

        @media (min-width: 389px) and (max-width: 550px) {
            margin-top: -2.7rem;
        }

        @media (min-width: 550px) and (max-width: 749px) {
            margin-top: -2.7rem;
        }
    }
}