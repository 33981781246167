@import '../../styles/colors';
@import '../../styles/mixins';


$background-pattern-retina: url('../../images/textures/wild_oliva_@2X.png');
$background-pattern: url('../../images/textures/wild_oliva.png');

.box {
    border-radius: 15px;
    padding: 3rem;
    margin-top: 2rem;
    background-image: $background-pattern;
    @include retina {
        background-image: $background-pattern-retina;
    }
    color: $neutral;

    .button-secondary {
        background: rgba(255, 255, 255, 0.5);
        color: black;
    }

    input {
        color: $accent;
    }

    @media (min-width: 550px) {
    }

    @media (min-width: 0px) and (max-width: 389px) {
        button {
            width: 100%;
        }
    }

    @media (min-width: 389px) and (max-width: 550px) {
        button {
            width: 100%;
        }
    }

    @media (min-width: 550px) and (max-width: 749px) {
        button {
            width: 100%;
        }
    }
}