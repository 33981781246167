/* Larger than mobile */
@mixin phone {
    @media (min-width: 370px) {
        @content;
    }
}

/* Larger than phablet (also point when grid becomes active) */
@mixin phablet {
    @media (min-width: 550px) {
        @content;
    }
}

/* Larger than tablet */
@mixin tablet {
    @media (min-width: 750px) {
        @content;
    }
}

/* Larger than desktop */
@mixin desktop {
    @media (min-width: 1000px) {
        @content;
    }
}

/* Larger than Desktop HD */
@mixin desktop-large {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin retina {
  @media
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    @content;
  }
}
