@import "mixins";

.hidden-default {
    display: none;
}

.list-unstyled {
    list-style: none;
}

@include phone {
    .visible-phone {
        display: inline-block;
    }

    .hidden-phone {
        display: none;
    }
}

@include phablet {
    .visible-phablet {
        display: inline-block;
    }

    .hidden-phablet {
        display: inline-block;
    }
}

@include tablet {
    .visible-tablet {
        display: inline-block;
    }

    .hidden-phone {
        display: none;
    }
}

[ng-click],
[data-ng-click],
[x-ng-click],
.nav, .pagination, .carousel, .panel-title a {
    cursor: pointer;
}
