.LoadedCustomScheduleAlert button.close {
    font-size: 24px;
}

.LoadedCustomScheduleAlert {
    font-size: 1.6rem;

    button {
        &.btn {
            margin: 0;
            font-size: 1.4rem;

            &:hover, &:focus, &:visited, &:focus-visible {
                border: none;
                // outline:0;
            }
        }

        &.close {
            font-size: 24px;
        }
    }
}