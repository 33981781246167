@import "../../styles/common";

.volume {
    margin-top: 0;
    .volume-title {
        font-size: 4.5rem;
        font-family: $scripture-font;
        text-align: center;
        font-variant: small-caps;
        padding-top: 2rem;
        margin-top: 0;
    }
    .volume-subtitle {
        font-size: 2.5rem;
        font-family: $scripture-font;
        text-align: center;
        font-variant: small-caps;
        padding-top: 10px;
        margin-top: 10px;
    }
}

ul.books {
    margin: 0 0 3rem 0;
    padding-left: 0;
    clear: both;
    list-style: none;
    
    // a {
    //     text-decoration: none;
    // }
    // a:hover {
    //     text-decoration: underline;
    // }
}
