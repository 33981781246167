@import 'styles/common';
@import 'styles/colors';
@import 'styles/mixins';

@import url(https://fonts.googleapis.com/css?family=Raleway:400,300,600);

$background-pattern-retina: url('images/textures/wild_oliva_@2X.png');
$background-pattern: url('images/textures/wild_oliva.png');


html {
    font-size: 62.5%;
}

html, body {
    margin: 0;
    padding: 0;
    background-image: $background-pattern;
    @include retina {
        background-image: $background-pattern-retina;
    }
    background-color: #333;
}

body {
    padding-top: $header-height;
    font-size: 2rem;
    line-height: 1.6;
    font-weight: 400;
    color: #222;
    font-family: "Raleway", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}